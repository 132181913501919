import { useLayoutEffect, useState } from 'react'

const OnWindowResize = () => {
  const [dimensions, setDimensions] = useState([0, 0])

  useLayoutEffect(() => {
    const updateSize = () => {
      const containerWidth = document.documentElement.clientWidth
      const containerHeight = document.documentElement.clientHeight

      setDimensions([containerWidth, containerHeight])
    }
    window.addEventListener('resize', updateSize, false)
    updateSize()

    return () => {
      window.removeEventListener('resize', updateSize, false)
    }
  }, [])

  return dimensions
}

export default OnWindowResize
